import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Home',
    component: () => import('@/components/layout/TheContainer'),
    meta: {
      auth: true
    },
    children: [
      { path: 'home', name: 'home', component: () => import('@/views/Home') },

      { path: 'user', name: 'user', component: () => import('@/views/User/Index') },
      { path: 'user/create', name: 'user-create', component: () => import('@/views/User/Form') },
      { path: 'user/:id', name: 'user-show', component: () => import('@/views/User/Show') },
      { path: 'user/:id/edit', name: 'user-edit', component: () => import('@/views/User/Form') },

      { path: 'pequrban', name: 'pequrban', component: () => import('@/views/Pequrban/Index') },
      { path: 'pequrban/create', name: 'pequrban-create', component: () => import('@/views/Pequrban/Form') },
      { path: 'pequrban/:id', name: 'pequrban-show', component: () => import('@/views/Pequrban/Show') },
      { path: 'pequrban/:id/edit', name: 'pequrban-edit', component: () => import('@/views/Pequrban/Form') },

      { path: 'qurban_warehouse', name: 'qurban-warehouse', component: () => import('@/views/QurbanWarehouse/Index') },
      { path: 'qurban_warehouse/create', name: 'qurban-warehouse-create', component: () => import('@/views/QurbanWarehouse/Form') },
      { path: 'qurban_warehouse/:id', name: 'qurban-warehouse-show', component: () => import('@/views/QurbanWarehouse/Show') },
      { path: 'qurban_warehouse/:id/edit', name: 'qurban-warehouse-edit', component: () => import('@/views/QurbanWarehouse/Form') },

      { path: 'distribusi', name: 'distribusi', component: () => import('@/views/Distribusi/Index') },
      { path: 'distribusi/create', name: 'distribusi-create', component: () => import('@/views/Distribusi/Form') },
      { path: 'distribusi/:id', name: 'distribusi-show', component: () => import('@/views/Distribusi/Show') },
      { path: 'distribusi/:id/edit', name: 'distribusi-edit', component: () => import('@/views/Distribusi/Form') },

      { path: 'monitoring', name: 'monitoring', component: () => import('@/views/Monitoring/Index') },
      { path: 'monitoring/create', name: 'monitoring-create', component: () => import('@/views/Monitoring/Form') },
      { path: 'monitoring/:id', name: 'monitoring-show', component: () => import('@/views/Monitoring/Show') },
      { path: 'monitoring/:id/edit', name: 'monitoring-edit', component: () => import('@/views/Monitoring/Form') },

      { path: 'app-setting', name: 'app-setting', component: () => import('@/views/AppSetting/Form') },

      { path: 'profile', name: 'profile', component: () => import('@/views/Profile') },


    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    // return desired position
    window.scrollTo(0,0)
  }
})

export default router
